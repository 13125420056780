import { initializeApp } from 'firebase/app';
import { getFirestore } from '@firebase/firestore'
import { getStorage } from "firebase/storage";
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
  apiKey: "AIzaSyBxcjrAeDqVKhgMbmjacaf15ntxsA4cMm0",
  authDomain: "yourfriday-4f51b.firebaseapp.com",
  projectId: "yourfriday-4f51b",
  storageBucket: "yourfriday-4f51b.appspot.com",
  messagingSenderId: "727482973574",
  appId: "1:727482973574:web:81a6055224f52a7ec34007",
  measurementId: "G-EZ4YHJSJ90"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);

const storage = getStorage(app);


export { db, storage, auth };
