import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './css/dashboard.css';
import './css/dashboard-resp.css';
import spImage from "./images/strong-password.png";
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';
import axios from 'axios';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './css/toastt.css';



import url from './constData';

function ForgotPassword() {

    const [fname, setFname] = useState("");
    const [numberSelected, setNumberSelected] = useState("");
    const [emailSelected, setEmailSelected] = useState("");
    const [showPass, setShowPass] = useState("");
    const [password, setPassword] = useState("");
    const [showOTP, setShowOTP] = useState(false);
    const [otp, setOtp] = useState("");

    const [token, setToken] = useState('');
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);


    const fetchData = async (event) => {
        event.preventDefault();
        setOpen(true);
        try {
            const response = await axios.post(`${url}/api/admin/forgotPassword`, {

                email: emailSelected,
            }, {
                headers: {
                    "Content-Type": "application/json; charset=UTF-8",
                }

            });

            if (response.status === 200) {

                toast.success("Email Sent!", {
                    position: toast.POSITION.TOP_RIGHT,
                    className: 'toast-message',
                });
                setShowOTP(true);
                setOpen(false);
            }
            else if (response.status === 400) {
                toast.error("Try Again", {
                    position: toast.POSITION.TOP_RIGHT,
                    className: 'toast-message',
                });
            }
            else {
                console.log("Status is not 200");
                setOpen(false);
            }

        } catch (error) {
            if (error.response.status === 400) {
                toast.error(`${error.response.data.msg}`, {
                    position: toast.POSITION.TOP_RIGHT,
                    className: 'toast-message',
                });
            }
            else {
                toast.error(`${error.response.data.error}`, {
                    position: toast.POSITION.TOP_RIGHT,
                    className: 'toast-message',
                });
            }
            console.log(`error status -> ${error.response.status}`);
            console.log(`error is here -> ${error.response.data.msg}`);
            setOpen(false);
        }
    }

    const sentOTP = async () => {
        try {
            const resp = await axios.post(`${url}/api/admin/verifyOTP`, {
                email: emailSelected,
                otp: otp,
                password: password,
            }, {
                headers: {
                    "Content-Type": "application/json; charset=UTF-8",
                }
            });

            if (resp.status === 200) {
                setOpen(false);
                sessionStorage.setItem("changedPassword", "yes");
                navigate("/");

                // toast.success("Password Changed", {
                //     position: toast.POSITION.TOP_RIGHT,
                //     className: 'toast-message',
                // });
            }
            else if (resp.status === 400) {
                toast.error(`${resp.data.msg}`, {
                    position: toast.POSITION.TOP_RIGHT,
                    className: 'toast-message',
                });
                setOpen(false);
            }
            else {
                console.log("Status is not 200");
                setOpen(false);
            }
        } catch (error) {
            if (error.response.status === 400) {
                toast.error(`${error.response.data.msg}`, {
                    position: toast.POSITION.TOP_RIGHT,
                    className: 'toast-message',
                });
            }
            else {
                toast.error(`${error.response.data.error}`, {
                    position: toast.POSITION.TOP_RIGHT,
                    className: 'toast-message',
                });
            }
            console.log(`error status -> ${error.response.status}`);
            console.log(`error is here -> ${error.response.data.msg}`);
            setOpen(false);
        }
    }


    return (
        <>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 0 }}
                open={open}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <ToastContainer />
            <div className="white_div">
            </div>
            <div className="yellow_div">
                <h1 className="login_text">Forgot Password</h1>
                <div className="username_div">
                    <form id='form1' className="form11" onSubmit={fetchData}>
                        {/* <label>Username</label>
                        <br />
                        <input type="text" placeholder="Enter your name" value={fname} onChange={e => setFname(e.target.value)} />
                        <br />
                        <label>Phone no.</label>
                        <br />
                        <input type="text" placeholder="Enter your phone no." value={numberSelected} onChange={e => setNumberSelected(e.target.value)} />
                        <br /> */}
                        <label>Email Id</label>
                        <input type="text" placeholder="Enter your email id" value={emailSelected} onChange={e => setEmailSelected(e.target.value)} />
                        <p className="infoNote">*If your email is Registered than you will get a OTP on your mail id.</p>
                        <br style={{ display: showOTP ? "block" : "none" }} />
                        <label style={{ display: showOTP ? "block" : "none" }}>OTP</label>
                        <input style={{ display: showOTP ? "block" : "none" }} type="text" placeholder="Enter OTP" value={otp} onChange={e => setOtp(e.target.value)} />

                        <label style={{ display: showOTP ? "block" : "none" }}>New Password</label>
                        <input style={{ display: showOTP ? "block" : "none" }} type="password" placeholder="Enter New Password" value={password} onChange={e => setPassword(e.target.value)} />

                        <button type="submit" form='form1' style={{ display: showOTP ? "none" : "block" }} className="signup_buttonn">Get OTP</button>
                        <button type="submit" onClick={sentOTP} style={{ display: showOTP ? "block" : "none" }} className="signup_buttonn">Set Password</button>

                    </form>

                </div>
            </div>
            <div>
                <img className="login_gif" src={spImage} alt="password-gif" />
            </div>
        </>
    )
}

export default ForgotPassword;