import React, { useState, useEffect } from "react";
import f from "./images/f.png";
// import studioIconImage from "./images/f.png";
// import notification from "./images/icons/notification.svg";
// import setting from "./images/icons/setting-icon.svg";
// import editIcon from "./images/icons/edit.svg";
// import deleteIcon from "./images/icons/delete.svg";
// import addIcon from "./images/icons/add.svg";
import blackImage from "./images/blackImage.png";
import "./css/dashboard.css";
import "./css/dashboard-resp.css";

import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";
import axios from "axios";
import url from "./constData";
import { Link, useNavigate } from "react-router-dom";
import {  signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "./firebaseSetup";

function AuditionSectionActive() {
  const [open, setOpen] = useState(false);
  const [auditionList, setAuditionList] = useState(null);
  const [filteredList, setFilteredList] = useState(null);
  const navigate = useNavigate();


  const clearAllData = () => {
    localStorage.clear();
    sessionStorage.clear();
    navigate("/");
  };

  const fetchData = async () => {
    setOpen(true);
    try {
      const response = await axios.get(`${url}/admin/api/audition/getActive`, {
        headers: {
          "Content-Type": "application/json; charset=UTF-8",
          "x-admin-token": localStorage.getItem("admin-token"),
        },
      });
    //   console.log(response.data);
      setAuditionList(response.data);
      setOpen(false);
    } catch (error) {
      console.log(`The error is -> ${error}`);
      setOpen(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const [submenu1, setSubmenu1] = useState(false);
  const [submenu2, setSubmenu2] = useState(true);

  const [editBtn, setEditBtn] = useState(false);
  const [fname, setFname] = useState("");
  const [loc, setLoc] = useState("");
  const [subsName, setSubsName] = useState("");
  const [audDesc, setAudDesc] = useState("");
  const [cat, setCat] = useState("");

  const dis1 = {
    display: "block",
  };
  const notDis1 = {
    display: "none",
  };
  const dis2 = {
    display: "block",
  };
  const notDis2 = {
    display: "none",
  };

  const hoverFunction1 = () => {
    if (submenu1 === true) {
      setSubmenu1(false);
    } else {
      setSubmenu1(true);
    }
  };
  const hoverFunction2 = () => {
    if (submenu2 === true) {
      setSubmenu2(false);
    } else {
      setSubmenu2(true);
    }
  };

  const [details, setDetails] = useState(null);

  const visible = {
    display: "block",
  };
  const notVisible = {
    display: "none",
  };

  const showDetails = (index) => {
    if (details === index) {
      setDetails(null);
    } else {
      setDetails(index);
    }
  };

  const editDetails = (auditiond) => {
    if (editBtn === true) {
      setEditBtn(false);
    //   console.log("true");
    } else {
      setCat(auditiond.category);
      setAudDesc(auditiond.bio);
      setSubsName(auditiond.subscriptionName);
      setFname(auditiond.fname);
      setLoc(auditiond.location);
      setEditBtn(true);

    //   console.log("false");
    }
  };

  const UploadDetails = async (audition) => {
    setOpen(true);
    setEditBtn(false);
    setDetails(null);
    setAuditionList(null);

    try {
      const resp = await axios.post(
        `${url}/admin/api/audition/editActive`,
        {
          userID: audition._id,
          fname: fname,
          subscription: subsName,
          location: loc,
          bio: audDesc,
          category: cat,
        },
        {
          headers: {
            "Content-Type": "application/json; charset=UTF-8",
            "x-admin-token": localStorage.getItem("admin-token"),
          },
        }
      );

    //   console.log(`here is the response -> ${resp.data}`);
      setAuditionList(resp.data);
      setOpen(false);
    } catch (error) {
      console.log(`here is the error -> ${error}`);
      setOpen(false);
    }
  };

  const DeleteEntry = async (audition) => {
    setOpen(true);
    setEditBtn(false);
    setDetails(null);
    setAuditionList(null);
    const email = audition.email;
  
   
    try {

      
      const resp = await axios.post(
        `${url}/admin/api/delete`,
        {
          userID: audition._id,
          user: "audition",
        },
        {
          headers: {
            "Content-Type": "application/json; charset=UTF-8",
            "x-admin-token": localStorage.getItem("admin-token"),
          },
        }
      );

      setAuditionList(resp.data);
      setOpen(false);
      
      await signInWithEmailAndPassword(
        auth,
        email.trim(),
        `${email.trim()}password`
      ).then((result) => {
        const user = result.user;
        user.delete();
        console.log("user deleted");
      });
    } catch (error) {
      console.log(`here is the error -> ${error}`);
      setOpen(false);
    }
  };

  const SearchChange = (event) => {
    setFilteredList(
      auditionList.filter((item) => {
        return (
          item.fname.toLowerCase().indexOf(event.target.value.toLowerCase()) !==
          -1
        );
      })
    );
  };

  const aaa = (
    <>
      <select
        value={subsName}
        onChange={(e) => setSubsName(e.target.value)}
        id="dd"
      >
        <option value="Free">Free</option>
        <option value="Silver">Silver</option>
        <option value="Gold">Gold</option>
        <option value="Platinum">Platinum</option>
      </select>
    </>
  );

  const textA = (
    <>
      <textarea
        value={audDesc}
        onChange={(e) => setAudDesc(e.target.value)}
        style={{ padding: "5px" }}
        cols="100"
        rows="10"
      ></textarea>
    </>
  );

  if (auditionList === null) {
    return (
      <>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={open}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <div className="main-yellow">
          <div className="left-side-menu">
            <div className="studio-title">
              {/* <img src={f} alt="profile-pic" /> */}
              <span className="studio-name">Admin</span>
            </div>
            <div className="main-menu">
              <Link to="/dashboard" className="dashboard-selected">
                <div className="main-menu-content">
                  <span> Dashboard</span>
                </div>
              </Link>
              <Link
                className="inbox-selected sub-menu"
                onClick={hoverFunction1}
              >
                <div className="main-menu-content">
                  <span>Studio Section</span>
                </div>
              </Link>
              <div className="dropdown-menu" style={submenu1 ? dis1 : notDis1}>
                <Link to="/studiosectionactive">
                  <div className="drop-active">
                    <span>Active User</span>
                  </div>
                </Link>
                <Link to="/studiosectioninactive">
                  <div className="drop-active">
                    <span>Inactive User</span>
                  </div>
                </Link>
                <Link to="/studiosubscription">
                  <div className="drop-active">
                    <span>Subscription</span>
                  </div>
                </Link>
                {/* <Link to="/studioquery">
                                    <div className="drop-active">
                                        <span>Query</span>
                                    </div>
                                </Link>
                                <Link to="/notificationstudio">
                                    <div className="drop-active">
                                        <span>Notification</span>
                                    </div>
                                </Link> */}
              </div>
              <Link className="jobs-selected" onClick={hoverFunction2}>
                <div className="main-menu-content ">
                  <span >Audition Section</span>
                </div>
              </Link>
              <div className="dropdown-menu" style={submenu2 ? dis2 : notDis2}>
                <Link to="/auditionsectionactive">
                  <div className="button-selected">
                    <span>Active User</span>
                  </div>
                </Link>
                <Link to="/auditionsectioninactive">
                  <div className="drop-active">
                    <span>Inactive User</span>
                  </div>
                </Link>
                <Link to="/auditionsubscription">
                  <div className="drop-active">
                    <span>Subscription</span>
                  </div>
                </Link>
                {/* <Link to="/studioquery">
                                    <div className="drop-active">
                                        <span>Query</span>
                                    </div>
                                </Link>
                                <Link to="/notificationaudition">
                                    <div className="drop-active">
                                        <span>Notification</span>
                                    </div>
                                </Link> */}
              </div>
              <Link to="/promoreq" className="connection-selected">
                <div className="main-menu-content">
                  <span>Promotion Request</span>
                </div>
              </Link>

              <Link to="/managementpage" className="invite-selected">
                <div className="main-menu-content">
                  <span>Management</span>
                </div>
              </Link>
              <Link to="/managePayment" className="invite-selected">
                <div className="main-menu-content">
                  <span>Payments</span>
                </div>
              </Link>
              {/* <Link to="/report-page" className="report-selected">
                <div className="main-menu-content">
                  <span>Report</span>
                </div>
              </Link> */}
              <Link to="/" className="invite-selected">
                <div className="main-menu-content" onClick={clearAllData}>
                  <span>Logout</span>
                </div>
              </Link>
            </div>
          </div>
        </div>
        <div className="main-white">
          <div className="top-div">
            <input type="text" placeholder="Search here..." />
            {/* <button className="notification-button"><img src={notification}
                            alt="notification" /></button>
                        <button className="setting-button"><img src={setting} alt="setting" /></button> */}
          </div>
        </div>
      </>
    );
  } else {
    return (
      <>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={open}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <div className="main-yellow">
          <div className="left-side-menu">
            <div className="studio-title">
              {/* <img src={f} alt="profile-pic" /> */}
              <span className="studio-name">Admin</span>
            </div>
            <div className="main-menu">
              <Link to="/dashboard" className="dashboard-selected">
                <div className="main-menu-content">
                  <span> Dashboard</span>
                </div>
              </Link>
              <Link
                className="inbox-selected sub-menu"
                onClick={hoverFunction1}
              >
                <div className="main-menu-content">
                  <span>Studio Section</span>
                </div>
              </Link>
              <div className="dropdown-menu" style={submenu1 ? dis1 : notDis1}>
                <Link to="/studiosectionactive">
                  <div className="drop-active">
                    <span>Active User</span>
                  </div>
                </Link>
                <Link to="/studiosectioninactive">
                  <div className="drop-active">
                    <span>Inactive User</span>
                  </div>
                </Link>
                <Link to="/studiosubscription">
                  <div className="drop-active">
                    <span>Subscription</span>
                  </div>
                </Link>
                {/* <Link to="/studioquery">
                                    <div className="drop-active">
                                        <span>Query</span>
                                    </div>
                                </Link>
                                <Link to="/notificationstudio">
                                    <div className="drop-active">
                                        <span>Notification</span>
                                    </div>
                                </Link> */}
              </div>
              <Link className="jobs-selected" onClick={hoverFunction2}>
                <div className="main-menu-content">
                  <span >Audition Section</span>
                </div>
              </Link>
              <div className="dropdown-menu" style={submenu2 ? dis2 : notDis2}>
                <Link to="/auditionsectionactive">
                  <div className="button-selected">
                    <span>Active User</span>
                  </div>
                </Link>
                <Link to="/auditionsectioninactive">
                  <div className="drop-active">
                    <span>Inactive User</span>
                  </div>
                </Link>
                <Link to="/auditionsubscription">
                  <div className="drop-active">
                    <span>Subscription</span>
                  </div>
                </Link>
                {/* <Link to="/studioquery">
                                    <div className="drop-active">
                                        <span>Query</span>
                                    </div>
                                </Link>
                                <Link to="/notificationaudition">
                                    <div className="drop-active">
                                        <span>Notification</span>
                                    </div>
                                </Link> */}
              </div>
              <Link to="/promoreq" className="connection-selected">
                <div className="main-menu-content">
                  <span>Promotion Request</span>
                </div>
              </Link>

              <Link to="/managementpage" className="invite-selected">
                <div className="main-menu-content">
                  <span>Management</span>
                </div>
              </Link>
              <Link to="/managePayment" className="invite-selected">
                <div className="main-menu-content">
                  <span>Payments</span>
                </div>
              </Link>
              {/* <Link to="/report-page" className="report-selected">
                <div className="main-menu-content">
                  <span>Report</span>
                </div>
              </Link> */}
              <Link to="/" className="invite-selected">
                <div className="main-menu-content" onClick={clearAllData}>
                  <span>Logout</span>
                </div>
              </Link>
            </div>
          </div>
        </div>
        <div className="main-white">
          <div className="top-div">
            <input
              type="text"
              placeholder="Search here..."
              onChange={SearchChange}
            />
            {/* <button className="notification-button"><img src={notification}
                            alt="notification" /></button>
                        <button className="setting-button"><img src={setting} alt="setting" /></button> */}
          </div>
          <div className="main-white-left1">
            <section className="popular-studios1">
              <div className="pop-stud1">
                <h2>Artist</h2>
                <div className="icons-area">
                  {/* <img src={editIcon} alt="edit-icon" />
                                    <img src={deleteIcon} alt="edit-icon" />
                                    <img src={addIcon} alt="edit-icon" /> */}
                </div>
              </div>
              <hr />
              <div className="p-s-title">
                <span>Name</span>
                <span>Category</span>
                <span>Subscription</span>
                <span>Country</span>
                {/* <span>Followers</span> */}
                <span>Applied Jobs</span>
              </div>
              <div className="scroll-area">
                {auditionList.length === 0 ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "100%",
                      height: "100%",
                      fontSize: "40px",
                      fontWeight: "bolder",
                      color: "grey",
                    }}
                  >
                    No Data Available
                  </div>
                ) : filteredList === null ? (
                  auditionList.map((audition, index) => (
                    <div style={{ position: "relative" }}>
                      <div
                        className="p-s-studio-details"
                        key={index}
                        onClick={() => showDetails(index)}
                      >
                        <span>{audition.fname}</span>
                        <span className="p-s-s-i">{audition.category}</span>
                        <span>{audition.subscriptionName}</span>
                        <span>{audition.location}</span>
                        {/* <span>456K</span> */}
                        <span>{audition.applied.length}</span>
                      </div>

                      <div
                        className="studio-desc-admin"
                        key={index}
                        style={details === index ? visible : notVisible}
                      >
                        <section className="desc-first">
                          <div className="st-name">
                            <div className="st-name-img">
                              <img
                                src={
                                  audition.profilePic === ""
                                    ? blackImage
                                    : audition.profilePic
                                }
                                alt="studio-icon"
                              />
                            </div>
                            <div className="st-name-name">
                              {editBtn ? (
                                <input
                                  style={{
                                    border: "1px solid black",
                                    width: "80%",
                                    height: "1rem",
                                    margin: "0",
                                    fontSize: "1.2rem",
                                  }}
                                  type="text"
                                  value={fname}
                                  onChange={(e) => setFname(e.target.value)}
                                />
                              ) : (
                                <span>{audition.fname}</span>
                              )}
                              {editBtn ? (
                                <input
                                  type="text"
                                  style={{
                                    border: "1px solid black",
                                    width: "80%",
                                    height: "1rem",
                                    margin: "0",
                                    fontSize: "1.2rem",
                                  }}
                                  value={loc}
                                  onChange={(e) => setLoc(e.target.value)}
                                />
                              ) : (
                                <span>({audition.location})</span>
                              )}
                            </div>
                          </div>
                          <div className="subs-std">
                            <h3>
                              Subscription:{" "}
                              {editBtn ? aaa : audition.subscriptionName}
                            </h3>
                            <span>{audition.daysLeft} Days left</span>
                          </div>
                        </section>
                        <section className="desc-second">
                          <span>
                            Category:{" "}
                            {editBtn ? (
                              <input
                                type="text"
                                style={{
                                  border: "1px solid black",
                                  width: "80%",
                                  height: "1rem",
                                  margin: "0",
                                  fontSize: "1.2rem",
                                }}
                                value={cat}
                                onChange={(e) => setCat(e.target.value)}
                              />
                            ) : (
                              audition.category
                            )}
                          </span>
                          {/* <span>Followers: 35K</span> */}
                        </section>
                        <section className="desc-third">
                          {editBtn ? textA : <p>{audition.bio}</p>}
                        </section>
                        {editBtn ? (
                          <section className="desc-fourth">
                            <button
                              className="btn"
                              onClick={() => editDetails(audition)}
                            >
                              {editBtn ? "Cancel" : "Edit"}
                            </button>
                            <button
                              className="btn"
                              onClick={() => DeleteEntry(audition)}
                            >
                              Delete
                            </button>
                            <button
                              className="btn"
                              onClick={() => UploadDetails(audition)}
                            >
                              Update
                            </button>
                          </section>
                        ) : (
                          <section className="desc-fourth">
                            <button
                              className="btn"
                              onClick={() => editDetails(audition)}
                            >
                              {editBtn ? "Cancel" : "Edit"}
                            </button>
                            <button
                              className="btn"
                              onClick={() => DeleteEntry(audition)}
                            >
                              Delete
                            </button>
                          </section>
                        )}
                      </div>
                    </div>
                  ))
                ) : (
                  filteredList.map((audition, index) => (
                    <>
                      <div
                        className="p-s-studio-details"
                        key={index}
                        onClick={() => showDetails(index)}
                      >
                        <span>{audition.fname}</span>
                        <span className="p-s-s-i">{audition.category}</span>
                        <span>{audition.subscriptionName}</span>
                        <span>{audition.location}</span>
                        {/* <span>456K</span> */}
                        <span>{audition.applied.length}</span>
                      </div>

                      <div
                        className="studio-desc-admin"
                        key={index}
                        style={details === index ? visible : notVisible}
                      >
                        <section className="desc-first">
                          <div className="st-name">
                            <div className="st-name-img">
                              <img
                                src={
                                  audition.profilePic === ""
                                    ? blackImage
                                    : audition.profilePic
                                }
                                alt="studio-icon"
                              />
                            </div>
                            <div className="st-name-name">
                              {editBtn ? (
                                <input
                                  style={{
                                    border: "1px solid black",
                                    width: "80%",
                                    height: "1rem",
                                    margin: "0",
                                    fontSize: "1.2rem",
                                  }}
                                  type="text"
                                  value={fname}
                                  onChange={(e) => setFname(e.target.value)}
                                />
                              ) : (
                                <span>{audition.fname}</span>
                              )}
                              {editBtn ? (
                                <input
                                  type="text"
                                  style={{
                                    border: "1px solid black",
                                    width: "80%",
                                    height: "1rem",
                                    margin: "0",
                                    fontSize: "1.2rem",
                                  }}
                                  value={loc}
                                  onChange={(e) => setLoc(e.target.value)}
                                />
                              ) : (
                                <span>({audition.location})</span>
                              )}
                            </div>
                          </div>
                          <div className="subs-std">
                            <h3>
                              Subscription:{" "}
                              {editBtn ? aaa : audition.subscriptionName}
                            </h3>
                            <span>{audition.daysLeft} Days left</span>
                          </div>
                        </section>
                        <section className="desc-second">
                          <span>
                            Category:{" "}
                            {editBtn ? (
                              <input
                                type="text"
                                style={{
                                  border: "1px solid black",
                                  width: "80%",
                                  height: "1rem",
                                  margin: "0",
                                  fontSize: "1.2rem",
                                }}
                                value={cat}
                                onChange={(e) => setCat(e.target.value)}
                              />
                            ) : (
                              audition.category
                            )}
                          </span>
                          {/* <span>Followers: 35K</span> */}
                        </section>
                        <section className="desc-third">
                          {editBtn ? textA : <pre>{audition.bio}</pre>}
                        </section>
                        {editBtn ? (
                          <section className="desc-fourth">
                            <button
                              className="btn"
                              onClick={() => editDetails(audition)}
                            >
                              {editBtn ? "Cancel" : "Edit"}
                            </button>
                            <button
                              className="btn"
                              onClick={() => DeleteEntry(audition)}
                            >
                              Delete
                            </button>
                            <button
                              className="btn"
                              onClick={() => UploadDetails(audition)}
                            >
                              Update
                            </button>
                          </section>
                        ) : (
                          <section className="desc-fourth">
                            <button
                              className="btn"
                              onClick={() => editDetails(audition)}
                            >
                              {editBtn ? "Cancel" : "Edit"}
                            </button>
                            <button
                              className="btn"
                              onClick={() => DeleteEntry(audition)}
                            >
                              Delete
                            </button>
                          </section>
                        )}
                      </div>
                    </>
                  ))
                )}

                {/* <div className="p-s-studio-details">
                                    <span>Raja Dey</span>
                                    <span className='p-s-s-i'>Actor</span>
                                    <span>Gold</span>
                                    <span>Zimbabwe</span>
                                    <span>456K</span>
                                    <span>456</span>
                                </div>
                                <div className="p-s-studio-details">
                                    <span>Raja Dey</span>
                                    <span className='p-s-s-i'>Actor</span>
                                    <span>Gold</span>
                                    <span>Zimbabwe</span>
                                    <span>456K</span>
                                    <span>456</span>
                                </div>
                                <div className="p-s-studio-details">
                                    <span>Raja Dey</span>
                                    <span className='p-s-s-i'>Actor</span>
                                    <span>Gold</span>
                                    <span>Zimbabwe</span>
                                    <span>456K</span>
                                    <span>456</span>
                                </div>
                                <div className="p-s-studio-details">
                                    <span>Raja Dey</span>
                                    <span className='p-s-s-i'>Actor</span>
                                    <span>Gold</span>
                                    <span>Zimbabwe</span>
                                    <span>456K</span>
                                    <span>456</span>
                                </div>
                                <div className="p-s-studio-details">
                                    <span>Raja Dey</span>
                                    <span className='p-s-s-i'>Actor</span>
                                    <span>Gold</span>
                                    <span>Zimbabwe</span>
                                    <span>456K</span>
                                    <span>456</span>
                                </div>
                                <div className="p-s-studio-details">
                                    <span>Raja Dey</span>
                                    <span className='p-s-s-i'>Actor</span>
                                    <span>Gold</span>
                                    <span>Zimbabwe</span>
                                    <span>456K</span>
                                    <span>456</span>
                                </div>
                                <div className="p-s-studio-details">
                                    <span>Raja Dey</span>
                                    <span className='p-s-s-i'>Actor</span>
                                    <span>Gold</span>
                                    <span>Zimbabwe</span>
                                    <span>456K</span>
                                    <span>456</span>
                                </div>
                                <div className="p-s-studio-details">
                                    <span>Raja Dey</span>
                                    <span className='p-s-s-i'>Actor</span>
                                    <span>Gold</span>
                                    <span>Zimbabwe</span>
                                    <span>456K</span>
                                    <span>456</span>
                                </div>
                                <div className="p-s-studio-details">
                                    <span>Raja Dey</span>
                                    <span className='p-s-s-i'>Actor</span>
                                    <span>Gold</span>
                                    <span>Zimbabwe</span>
                                    <span>456K</span>
                                    <span>456</span>
                                </div> */}
              </div>
            </section>
          </div>
         </div>
      </>
    );
  }
}

export default AuditionSectionActive;
