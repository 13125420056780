import './css/Login.css';
import './css/index-resp.css';
import React, { useState, useEffect } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';
import axios from 'axios';
import spImage from "./images/strong-password.png";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './css/toastt.css';

import url from './constData';



function Login() {


    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [token, setToken] = useState("");
    const [open, setOpen] = useState(false);


    const navigate = useNavigate();

    async function fetchData() {
        setOpen(true);
        try {
            const response = await axios.post(`${url}/admin/api/login`, {
                email: email,
                password: password,
            }, {
                headers: {
                    "Content-Type": "application/json; charset=UTF-8",
                }
            });
            // console.log(response);

            setToken(response.data.token);
            localStorage.setItem("admin-token", response.data.token);
            localStorage.setItem("userData", JSON.stringify(response.data));
            setOpen(false);
        } catch (error) {
            console.log(`There is some error: ${error}`);
            setOpen(false);
            toast.error(`${error.response.data.msg}`, {
                position: toast.POSITION.TOP_RIGHT,
                className: "toast-message",
              });
        }
    }

    useEffect(() => {
        if (sessionStorage.getItem("changedPassword") === "yes") {
            toast.success("Password Changed", {
                position: toast.POSITION.TOP_RIGHT,
                className: 'toast-message',
            });
            sessionStorage.clear();
        }
        if (localStorage.getItem("admin-token") !== null && localStorage.getItem("admin-token") !== "") {
            navigate("/dashboard");

        }


    }, [token])



    const handelSubmit = async (event) => {
        event.preventDefault();

        await fetchData();
        // console.log("after fetchData");
    }

    const forgotPassword = () => {
        navigate("/forgot-password");
    }


    return (
        <>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={open}
            // onClick={handleClose}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <ToastContainer />
            <div className="white-div">
            </div>
            <div className="yellow-div">
                <h1 className="login-text">LOGIN</h1>
                <div className="username-div">
                    <form onSubmit={handelSubmit}>
                        <label>Email</label>
                        <br />
                        <input type="text" name='email' value={email} onChange={(event) => setEmail(event.target.value)} placeholder="Enter your name" />
                        <br />
                        <label>Password</label>
                        <br />
                        <input type="password" name='password' value={password} onChange={(event) => setPassword(event.target.value)} placeholder="Enter password" />
                        <p onClick={forgotPassword}>Forgot Password?</p>
                        <div className="login-button-area">
                            <button className="login-button" type='submit'>Log in</button>
                        </div>
                    </form>
                    <div className="account-text">
                        <h4>Don't have an account yet?</h4>
                        <h4 className="signup"><Link to="/signup">Sign up</Link></h4>
                    </div>

                </div>
            </div>
            <div>
                <img className="login-gif" src={spImage} alt="password-gif" />
            </div>
        </>
    );
}

export default Login
