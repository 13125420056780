import React, { useState, useEffect } from 'react';
import f from './images/f.png';
// import notification from './images/icons/notification.svg';
// import setting from './images/icons/setting-icon.svg';
// import stImage from './images/f3.jpg';
// import blackImage from './images/blackImage.jpg'
import './css/dashboard.css';
import './css/dashboard-resp.css';

import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';
import axios from 'axios';
import url from './constData';


import { Link, useNavigate } from "react-router-dom";




function PromoReq() {

    const [open, setOpen] = useState(false);
    const [promoList, setPromoList] = useState(null);
    const [filteredList, setFilteredList] = useState(null);

    const navigate = useNavigate();


    const clearAllData = () => {
        localStorage.clear();
        sessionStorage.clear();
        navigate("/");

    }


    const fetchData = async () => {
        setOpen(true);
        try {
            const response = await axios.get(`${url}/admin/api/studio/getPromotionRequest`, {
                headers: {
                    "Content-Type": "application/json; charset=UTF-8",
                    "x-admin-token": localStorage.getItem("admin-token"),
                }
            });
            // console.log(response.data);
            setPromoList(response.data);
            setOpen(false);
        } catch (error) {
            console.log(`The error is -> ${error}`);
            setOpen(false);
        }
    }

    useEffect(() => {
        fetchData();


    }, [])

    const [submenu1, setSubmenu1] = useState(false);
    const [submenu2, setSubmenu2] = useState(false);

    const [editBtn, setEditBtn] = useState(false);
    const [fname, setFname] = useState("");
    const [loc, setLoc] = useState("");
    const [subsName, setSubsName] = useState("");
    const [audDesc, setAudDesc] = useState("");
    const [cat, setCat] = useState("");

    const [promotionList, setPromotionList] = useState(null);


    const dis1 = {
        display: "block"
    }
    const notDis1 = {
        display: "none"
    }
    const dis2 = {
        display: "block"
    }
    const notDis2 = {
        display: "none"
    }


    const hoverFunction1 = () => {
        if (submenu1 === true) {
            setSubmenu1(false);
        }
        else {
            setSubmenu1(true);
        }
    };
    const hoverFunction2 = () => {
        if (submenu2 === true) {
            setSubmenu2(false);
        }
        else {
            setSubmenu2(true);
        }
    };

    const [details, setDetails] = useState(false);

    const visible = {
        display: "block"
    }
    const notVisible = {
        display: "none"
    }


    const showDetails = (index) => {
        if (details === index) {
            setDetails(null);
        }
        else {
            setDetails(index);
        }

    };


    const editDetails = (auditiond) => {
        if (editBtn === true) {
            setEditBtn(false);
            // console.log("true");
        }
        else {
            setCat(auditiond.category)
            setAudDesc(auditiond.bio);
            setSubsName(auditiond.subscriptionName);
            setFname(auditiond.fname);
            setLoc(auditiond.location)
            setEditBtn(true);

            // console.log("false");
        }
    };

    // const UploadDetails = async (audition) => {
    //     setOpen(true);
    //     setEditBtn(false);
    //     setDetails(false);
    //     setPromoList(null);

    //     try {
    //         const resp = await axios.post(`${url}/admin/api/audition/editActive`, {
    //             userID: audition._id,
    //             fname: fname,
    //             subscription: subsName,
    //             location: loc,
    //             bio: audDesc,
    //             category: cat,
    //         }, {
    //             headers: {
    //                 "Content-Type": "application/json; charset=UTF-8",
    //                 "x-admin-token": localStorage.getItem("admin-token"),
    //             }
    //         });

    //         console.log(`here is the response -> ${resp.data}`);
    //         setPromoList(resp.data);
    //         setOpen(false);
    //     } catch (error) {
    //         console.log(`here is the error -> ${error}`);
    //         setOpen(false);
    //     }
    // }

    const SearchChange = (event) => {
        setFilteredList(promoList.filter((item) => {
            return item.studioName.toLowerCase().indexOf(event.target.value.toLowerCase()) !== -1;
        }));
    }

    const aaa = <>

        <select value={subsName} onChange={e => setSubsName(e.target.value)} id="dd">
            <option value="Free">Free</option>
            <option value="Gold">Gold</option>
            <option value="Platinum">Platinum</option>
        </select>
    </>


    const textA = <>
        <textarea value={audDesc} onChange={e => setAudDesc(e.target.value)} style={{ padding: "5px" }} cols="100" rows="10"></textarea>
    </>




    const acceptPromotion = async (jobId, index) => {
        // console.log(jobId);
        setOpen(true);
        setPromotionList(null);
        showDetails(index);
        try {
            const resp = await axios.post(`${url}/admin/api/studio/acceptPromotionReq`, {
                jobId: jobId,
            }, {
                headers: {
                    "Content-Type": "application/json; charset=UTF-8",
                    "x-admin-token": localStorage.getItem("admin-token"),
                }
            });
            // console.log(resp.data.promotionRequest);
            setPromotionList(resp.data.promotionRequest);
            await fetchData();
            setOpen(false);
        } catch (error) {
            console.log(`here is error => ${error}`);
            setOpen(false);
        }
    }

    if (promoList === null) {
        return (
            <>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={open}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
                <div className="main-yellow">
                    <div className="left-side-menu">
                        <div className="studio-title">
                            {/* <img src={f} alt="profile-pic" /> */}
                            <span className="studio-name">Admin</span>
                        </div>
                        <div className="main-menu">
                            <Link to="/dashboard" className="dashboard-selected">
                                <div className="main-menu-content">
                                    <span> Dashboard</span>
                                </div>
                            </Link>
                            <Link className="inbox-selected sub-menu" onClick={hoverFunction1}>
                                <div className="main-menu-content">
                                    <span>Studio Section</span>
                                </div>
                            </Link>
                            <div className="dropdown-menu" style={submenu1 ? dis1 : notDis1}>
                                <Link to="/studiosectionactive">
                                    <div className="drop-active">
                                        <span>Active User</span>
                                    </div>
                                </Link>
                                <Link to="/studiosectioninactive">
                                    <div className="drop-active">
                                        <span>Inactive User</span>
                                    </div>
                                </Link>
                                <Link to="/studiosubscription">
                                    <div className="drop-active">
                                        <span>Subscription</span>
                                    </div>
                                </Link>
                                {/* <Link to="/studioquery">
                                    <div className="drop-active">
                                        <span>Query</span>
                                    </div>
                                </Link>
                                <Link to="/notificationstudio">
                                    <div className="drop-active">
                                        <span>Notification</span>
                                    </div>
                                </Link> */}
                            </div>
                            <Link className="jobs-selected" onClick={hoverFunction2}>
                                <div className="main-menu-content" >
                                    <span>Audition Section</span>
                                </div>
                            </Link>
                            <div className="dropdown-menu" style={submenu2 ? dis2 : notDis2}>
                                <Link to="/auditionsectionactive">
                                    <div className="drop-active">
                                        <span>Active User</span>
                                    </div>
                                </Link>
                                <Link to="/auditionsectioninactive">
                                    <div className="drop-active">
                                        <span>Inactive User</span>
                                    </div>
                                </Link>
                                <Link to="/auditionsubscription">
                                    <div className="drop-active">
                                        <span>Subscription</span>
                                    </div>
                                </Link>
                                {/* <Link to="/studioquery">
                                    <div className="drop-active">
                                        <span>Query</span>
                                    </div>
                                </Link>
                                <Link to="/notificationaudition">
                                    <div className="drop-active">
                                        <span>Notification</span>
                                    </div>
                                </Link> */}
                            </div>
                            <Link to="/promoreq" className="button-selected">
                                <div className="main-menu-content">
                                    <span>Promotion Request</span>
                                </div>
                            </Link>

                            <Link to='/managementpage' className="invite-selected">
                                <div className="main-menu-content">
                                    <span>Management</span>
                                </div>
                            </Link>
                            <Link to='/managePayment' className="invite-selected">
                                <div className="main-menu-content">
                                    <span>Payments</span>
                                </div>
                            </Link>
                            {/* <Link to='/report-page' className="report-selected">
                                <div className="main-menu-content">
                                    <span>Report</span>
                                </div>
                            </Link> */}
                            <Link to='/' className="invite-selected">
                                <div className="main-menu-content" onClick={clearAllData}>
                                    <span>Logout</span>
                                </div>
                            </Link>



                        </div>
                    </div>
                </div>
                <div className="main-white">
                    <div className="top-div">
                        <input type="text" placeholder="Search here..." />
                        {/* <button className="notification-button"><img src={notification}
                            alt="notification" /></button>
                        <button className="setting-button"><img src={setting} alt="setting" /></button> */}
                    </div>
                </div>
            </>
        );
    } else {
        return (
            <>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={open}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
                <div className="main-yellow">
                    <div className="left-side-menu">
                        <div className="studio-title">
                            {/* <img src={f} alt="profile-pic" /> */}
                            <span className="studio-name">Admin</span>
                        </div>
                        <div className="main-menu">
                            <Link to="/dashboard" className="dashboard-selected">
                                <div className="main-menu-content">
                                    <span> Dashboard</span>
                                </div>
                            </Link>
                            <Link className="inbox-selected sub-menu" onClick={hoverFunction1}>
                                <div className="main-menu-content">
                                    <span>Studio Section</span>
                                </div>
                            </Link>
                            <div className="dropdown-menu" style={submenu1 ? dis1 : notDis1}>
                                <Link to="/studiosectionactive">
                                    <div className="drop-active">
                                        <span>Active User</span>
                                    </div>
                                </Link>
                                <Link to="/studiosectioninactive">
                                    <div className="drop-active">
                                        <span>Inactive User</span>
                                    </div>
                                </Link>
                                <Link to="/studiosubscription">
                                    <div className="drop-active">
                                        <span>Subscription</span>
                                    </div>
                                </Link>
                                {/* <Link to="/studioquery">
                                    <div className="drop-active">
                                        <span>Query</span>
                                    </div>
                                </Link>
                                <Link to="/notificationstudio">
                                    <div className="drop-active">
                                        <span>Notification</span>
                                    </div>
                                </Link> */}
                            </div>
                            <Link className="jobs-selected" onClick={hoverFunction2}>
                                <div className="main-menu-content" >
                                    <span>Audition Section</span>
                                </div>
                            </Link>
                            <div className="dropdown-menu" style={submenu2 ? dis2 : notDis2}>
                                <Link to="/auditionsectionactive">
                                    <div className="drop-active">
                                        <span>Active User</span>
                                    </div>
                                </Link>
                                <Link to="/auditionsectioninactive">
                                    <div className="drop-active">
                                        <span>Inactive User</span>
                                    </div>
                                </Link>
                                <Link to="/auditionsubscription">
                                    <div className="drop-active">
                                        <span>Subscription</span>
                                    </div>
                                </Link>
                                {/* <Link to="/studioquery">
                                    <div className="drop-active">
                                        <span>Query</span>
                                    </div>
                                </Link>
                                <Link to="/notificationaudition">
                                    <div className="drop-active">
                                        <span>Notification</span>
                                    </div>
                                </Link> */}
                            </div>
                            <Link to="/promoreq" className="button-selected">
                                <div className="main-menu-content">
                                    <span>Promotion Request</span>
                                </div>
                            </Link>

                            <Link to='/managementpage' className="invite-selected">
                                <div className="main-menu-content">
                                    <span>Management</span>
                                </div>
                            </Link>
                            <Link to='/managePayment' className="invite-selected">
                                <div className="main-menu-content">
                                    <span>Payments</span>
                                </div>
                            </Link>
                            {/* <Link to='/report-page' className="report-selected">
                                <div className="main-menu-content">
                                    <span>Report</span>
                                </div>
                            </Link> */}
                            <Link to='/' className="invite-selected">
                                <div className="main-menu-content" onClick={clearAllData}>
                                    <span>Logout</span>
                                </div>
                            </Link>



                        </div>
                    </div>
                </div>
                <div className="main-white">
                    <div className="top-div">
                        <input type="text" placeholder="Search here..." onChange={SearchChange} />
                        {/* <button className="notification-button"><img src={notification}
                            alt="notification" /></button>
                        <button className="setting-button"><img src={setting} alt="setting" /></button> */}
                    </div>
                    <div className="main-white-left1">
                        <div className="query-main-area">
                            {
                                promoList.length === 0 ? <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%", height: "100%", fontSize: "40px", fontWeight: "bolder", color: "grey" }}>No Data Available</div> :
                                    filteredList === null ? promoList.map((promo, index) =>
                                        <div key={index} onClick={() => showDetails(index)}>
                                            <div className="query">
                                                <div className="q-img">
                                                    <img src={promo.images[0]} alt="Studio-Image" />
                                                </div>
                                                <div className="q-details">
                                                    <h3>{promo.studioName}</h3>
                                                    <p>{promo.description.length > 255 ? promo.description.substring(0, 255) : promo.description}</p>
                                                </div>
                                                <div className="studio-desc-admin" style={details === index ? visible : notVisible}>
                                                    <section className="desc-first">
                                                        <div className="st-name">
                                                            <div className="st-name-img">
                                                                <img src={promo.images[0]} alt="studio-icon" />
                                                            </div>
                                                            <div className="st-name-name">

                                                                <span>{promo.studioName}</span>

                                                                <span>({promo.location})</span>


                                                            </div>
                                                        </div>
                                                        {/* <div className="subs-std">
                                                        <h3>Subscription: {studio.subscriptionName}</h3>
                                                        <span>{studio.daysLeft} Days left</span>
                                                    </div> */}
                                                    </section>
                                                    <section className="desc-second">
                                                        <span>Studio: {promo.studioName}</span>
                                                        {/* <span>Followers: {studio.followers.length}</span> */}
                                                    </section>
                                                    <section className="desc-third">
                                                        <p>{promo.description}</p>
                                                    </section>
                                                    <section className="desc-fourth">
                                                        <button style={{ cursor: "pointer" }} className="btn" onClick={promo.promoted ? (event) => {
                                                            event.preventDefault();
                                                            event.stopPropagation();
                                                        } : (event) => {
                                                            event.preventDefault();
                                                            event.stopPropagation();
                                                            acceptPromotion(promo._id, index)
                                                        }}>{promo.promoted ? "Accepted" : "Done"}</button>
                                                        {/* <button className="btn" onClick={() => DeleteEntry(studio)}>Delete</button> */}

                                                    </section>

                                                </div>
                                            </div>

                                        </div>
                                    ) : filteredList.map((promo, index) =>
                                        <div key={index}>
                                            <div className="query">
                                                <div className="q-img">
                                                    <img src={promo.images[0]} alt="Studio-Image" />
                                                </div>
                                                <div className="q-details">
                                                    <h3>{promo.studioName}</h3>
                                                    <p>{promo.description}</p>
                                                </div>
                                            </div>
                                        </div>)
                            }

                            {/* <div className="query">
                                <div className="q-img">
                                    <img src={stImage} alt="Studio-Image" />
                                </div>
                                <div className="q-details">
                                    <h3>Fuga et ut Studio</h3>
                                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Iusto, illum dicta vel quia culpa minima delectus quod. Corrupti quia quos excepturi, quas dolorem sapiente culpa qui, optio, asperiores illo alias?</p>
                                </div>
                            </div>
                            <div className="query">
                                <div className="q-img">
                                    <img src={stImage} alt="Studio-Image" />
                                </div>
                                <div className="q-details">
                                    <h3>Fuga et ut Studio</h3>
                                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Iusto, illum dicta vel quia culpa minima delectus quod. Corrupti quia quos excepturi, quas dolorem sapiente culpa qui, optio, asperiores illo alias?</p>
                                </div>
                            </div>
                            <div className="query">
                                <div className="q-img">
                                    <img src={stImage} alt="Studio-Image" />
                                </div>
                                <div className="q-details">
                                    <h3>Fuga et ut Studio</h3>
                                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Iusto, illum dicta vel quia culpa minima delectus quod. Corrupti quia quos excepturi, quas dolorem sapiente culpa qui, optio, asperiores illo alias?</p>
                                </div>
                            </div>
                            <div className="query">
                                <div className="q-img">
                                    <img src={stImage} alt="Studio-Image" />
                                </div>
                                <div className="q-details">
                                    <h3>Fuga et ut Studio</h3>
                                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Iusto, illum dicta vel quia culpa minima delectus quod. Corrupti quia quos excepturi, quas dolorem sapiente culpa qui, optio, asperiores illo alias?</p>
                                </div>
                            </div>
                            <div className="query">
                                <div className="q-img">
                                    <img src={stImage} alt="Studio-Image" />
                                </div>
                                <div className="q-details">
                                    <h3>Fuga et ut Studio</h3>
                                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Iusto, illum dicta vel quia culpa minima delectus quod. Corrupti quia quos excepturi, quas dolorem sapiente culpa qui, optio, asperiores illo alias?</p>
                                </div>
                            </div>
                            <div className="query">
                                <div className="q-img">
                                    <img src={stImage} alt="Studio-Image" />
                                </div>
                                <div className="q-details">
                                    <h3>Fuga et ut Studio</h3>
                                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Iusto, illum dicta vel quia culpa minima delectus quod. Corrupti quia quos excepturi, quas dolorem sapiente culpa qui, optio, asperiores illo alias?</p>
                                </div>
                            </div>
                            <div className="query">
                                <div className="q-img">
                                    <img src={stImage} alt="Studio-Image" />
                                </div>
                                <div className="q-details">
                                    <h3>Fuga et ut Studio</h3>
                                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Iusto, illum dicta vel quia culpa minima delectus quod. Corrupti quia quos excepturi, quas dolorem sapiente culpa qui, optio, asperiores illo alias?</p>
                                </div>
                            </div>
                            <div className="query">
                                <div className="q-img">
                                    <img src={stImage} alt="Studio-Image" />
                                </div>
                                <div className="q-details">
                                    <h3>Fuga et ut Studio</h3>
                                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Iusto, illum dicta vel quia culpa minima delectus quod. Corrupti quia quos excepturi, quas dolorem sapiente culpa qui, optio, asperiores illo alias?</p>
                                </div>
                            </div>
                            <div className="query">
                                <div className="q-img">
                                    <img src={stImage} alt="Studio-Image" />
                                </div>
                                <div className="q-details">
                                    <h3>Fuga et ut Studio</h3>
                                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Iusto, illum dicta vel quia culpa minima delectus quod. Corrupti quia quos excepturi, quas dolorem sapiente culpa qui, optio, asperiores illo alias?</p>
                                </div>
                            </div>
                            <div className="query">
                                <div className="q-img">
                                    <img src={stImage} alt="Studio-Image" />
                                </div>
                                <div className="q-details">
                                    <h3>Fuga et ut Studio</h3>
                                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Iusto, illum dicta vel quia culpa minima delectus quod. Corrupti quia quos excepturi, quas dolorem sapiente culpa qui, optio, asperiores illo alias?</p>
                                </div>
                            </div>
                            <div className="query">
                                <div className="q-img">
                                    <img src={stImage} alt="Studio-Image" />
                                </div>
                                <div className="q-details">
                                    <h3>Fuga et ut Studio</h3>
                                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Iusto, illum dicta vel quia culpa minima delectus quod. Corrupti quia quos excepturi, quas dolorem sapiente culpa qui, optio, asperiores illo alias?</p>
                                </div>
                            </div> */}
                        </div>
                    </div>

                </div>
            </>
        );
    }


}

export default PromoReq
