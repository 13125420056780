import React, { useState, useEffect } from 'react';
import './css/signup.css';
import './css/signup-resp.css';
import spImage from "./images/strong-password.png";
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { Link, useNavigate } from 'react-router-dom';
import url from './constData';

function Signup() {

    const [open, setOpen] = useState(false);
    const [fullName, setFullName] = useState("");
    const [number, setNumber] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [token, setToken] = useState("");
    const [passcode, setPasscode] = useState("");

    const navigate = useNavigate();

    async function fetchData() {
        setOpen(true);
        try {
            const response = await axios.post(`${url}/admin/api/signup`, {
                fullName: fullName,
                number: number,
                email: email,
                password: password,
                passcode: passcode
            }, {
                headers: {
                    "Content-Type": "application/json; charset=UTF-8",
                }
            });
            // console.log(response);

            setToken(response.data.token);
            localStorage.setItem("admin-token", response.data.token);
            localStorage.setItem("userData", JSON.stringify(response.data));
            setOpen(false);
        } catch (error) {
            console.log(`There is some error: ${error}`);
            toast.error(`${error.response.data.msg}`, {
                position: toast.POSITION.TOP_RIGHT,
                className: "toast-message",
              });
            setOpen(false);
        }
    }

    useEffect(() => {
        if (localStorage.getItem("admin-token") !== null && localStorage.getItem("admin-token") !== "") {
            navigate("/dashboard");
        }
    }, [token])
    
    const handelSubmit = async (event) => {
        event.preventDefault();

        await fetchData();
        // console.log("after fetchData");


    }




    return (
        <>
            <ToastContainer />
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={open}
            // onClick={handleClose}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <div className="white-div">
            </div>
            <div className="yellow-div">
                <h1 className="login-text1">Sign up</h1>
                <div className="username-div1" >
                    <form onSubmit={handelSubmit} style={{height:'50vh'}}>
                        <label>Full Name</label>
                        <br />
                        <input type="text" name='fullName' value={fullName} onChange={(event) => setFullName(event.target.value)} placeholder="Enter your name" />
                        <br />
                        <label>Phone no.</label>
                        <br />
                        <input type="text" name='number' value={number} onChange={(event) => setNumber(event.target.value)} placeholder="Enter your phone no." />
                        <br />
                        <label>Email Id</label>
                        <br />
                        <input type="text" name='email' value={email} onChange={(event) => setEmail(event.target.value)} placeholder="Enter your email id" />
                        <br />
                        <label>Password</label>
                        <br />
                        <input type="password" name='password' value={password} onChange={(event) => setPassword(event.target.value)} placeholder="Enter password" />
                        <br />
                        <label>Passcode</label>
                        <br />
                        <input type="password" name='passcode' value={passcode} onChange={(event) => setPasscode(event.target.value)} placeholder="Enter passcode" />
                        <div className="signup-button-area">
                            <button type='submit'>Sign up</button>
                        </div>
                    </form>
                </div>
            </div>
            <div>
                <img className="login-gif" src={spImage} alt="password-gif" />
            </div>
        </>
    )
}

export default Signup
